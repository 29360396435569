import '../style/bsp/bsp-style.scss'

var nextSlide = undefined;
$(document).ready(function() {
	gmCanLoad ();
"use strict";
	activCheck();
	$('#carousel-alert').carousel({
  		interval: 6000
	});	
	$('#carousel-headline').carousel({
  		interval: false
	});	
	$('#carousel-example-generic').carousel({
  		interval: 6000
	});	
	$('#carousel-example-generic .carousel-indicators li').on('click', function() {
		nextSlide = parseInt($(this).attr( 'data-slide-to' ));
	});
	$('#carousel-example-generic').on('slide.bs.carousel', function(ev) {
		if(!nextSlide) {
			var dir = ev.direction === 'right' ? 'prev' : 'next';
			$('#carousel-alert').carousel(dir);
		} else {
			$('#carousel-alert').carousel(nextSlide);
			nextSlide = undefined;
		}
	});
	$('.deliverables-tile .frame').on('click', function() {
		var url = $(this).attr( 'data-target-name' );
		window.open(url, '_self');
	});
	$('#standortauswahlkontakt .deliverables-tile').on( "click", function () {
		var newLoc = "formular"+$(this).attr('id');
		var searchOldLoc = $('#main .inside .location-form.active').attr('id');
		if($(this).hasClass('active')){
			$(this).removeClass('active');
			$('#'+searchOldLoc+'').removeClass('active');
		} else {
			$('#standortauswahlkontakt .deliverables-tile').removeClass('active');
			$('#main .inside .location-form.active').removeClass('active');
			$(this).addClass('active');
			$('#'+newLoc+'').addClass('active');
			
			var searchActiveLoc = $('#main .inside .location-form.active');
			if(searchActiveLoc.length){
				$('html, body').animate({
				  scrollTop: searchActiveLoc.offset().top-110
				}, 700);
			}	
		}
			
			
	});
	
	
});

function activCheck(){
"use strict";

 var searchActiveLoc = $('#main .inside .location-form.active');
 var searchActiveTae = $('#main .inside .taetigkeit.click-item');
	if(searchActiveLoc.length){
		$('html, body').animate({
          scrollTop: searchActiveLoc.offset().top-220
        }, 0);
	} else if (searchActiveTae.length){
		var spacer;
		if (!$('body.mobile').length){
			if($('#main .inside .taetigkeit.click-item.gray-background').length){
				spacer = 280;
			} else {
				spacer = 220;
			}
		} else {
			if($('#main .inside .taetigkeit.click-item.gray-background').length){
				spacer = 220;
			} else {
				spacer = 120;
			}
		}
		console.log(spacer);
		$('html, body').animate({
          scrollTop: searchActiveTae.offset().top-spacer
        }, 0);
	}	
}
function gmCanLoad () {
    
    if(cookiebar.issetCookie(4) && $( "#map" ).length) {
        console.log("geht1");
        (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?console.warn(p+" only loads once. Ignoring:",g):d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})
        ({key: "AIzaSyBYvgC4YMDo-yf0C5Exp3rs2ZbQhBxuxm8", v: "beta"}); //"beta" befor
        
        $("#map .cookie-not-allowed").addClass("display-none");
        cookiebar.addModule(4, initMapbig);
    } else if(!cookiebar.issetCookie(4) && $( "#map" ).length) {
        console.log("geht2");
            cookiebar.addModule(4, gmCanLoad, {
                selector: '#withoutCookie',           // [required: string, HTMLElement] Defines the element in which the message is output
                message: 'Ich bin damit einverstanden, dass mir Inhalte von Google Maps angezeigt werden. Sie können die Einbettung solcher Inhalte in unseren <a href="href="javascript:cookiebar.show(true);"">Cookie-Einstellungen</a> jederzeit wieder blockieren.',           // [optional: string] The text to be displayed
                button: {                       // [optional: object]
                    show: true,                 // [required: bool]   Extends the output by a confirmation button,
                    text: 'Google Maps erlauben', // [optional: string] Button text
                    type: 'button',             // [optional: string] Button type
                    classes: 'o-button-primary'     // [optional: string] Own CSS classes for the button separated by spaces
                }
            });
        
    }
}